import axios from "axios";
import {autoUrl} from "@/utils/autoUrl";

const connectApi = {
    saveConnect: '/basis/connect/save',
    connectWishPage: '/basis/connectWish/page',
    connectWishInfo: '/basis/connectWish/info',
    connectWish: '/basis/connectWish',
    likeWish: '/basis/connectWish/like/',
}

export function saveConnect(parameter) {
    return axios({
        method: 'post',
        url: connectApi.saveConnect,
        data: parameter
    });
}

export function saveConnectWish(parameter) {
    return axios({
        method: 'post',
        url: connectApi.connectWish,
        data: parameter
    });
}

export function connectWishList(searchForm) {
    return axios({
        method: 'get',
        url: connectApi.connectWishPage + autoUrl(searchForm),
    });
}

export function connectWishInfo(wishCode) {
    return axios({
        method: 'get',
        url: connectApi.connectWishInfo + "/" + wishCode,
    });
}

/**
 * 点赞
 * @param wishCode
 * @returns {AxiosPromise}
 */
export function likeWish(wishCode) {
    return axios({
        method: 'put',
        url: connectApi.likeWish + wishCode,
    });
}
