<template>
  <div>
    <div class="backgroundImage">
      <video class="video"
             loop=""
             muted=""
             autoplay="autoplay"
             controlsList="nodownload"
             disabledPictureInPicture
             src="@/assets/img/home/43bac53a21a74b48ac954ce27a1e342f.mp4"
             style="">
      </video>

      <!--        src="https://az3.sfo2.cdn.digitaloceanspaces.com/bingdesktop/43bac53a21a74b48ac954ce27a1e342f.mp4" -->
      <!--      src="https://prod-streaming-video-msn-com.akamaized.net/394e73b6-e7ac-4b5d-8063-80d421afb446/0e09af42-f217-4deb-8580-fb0ab5420f6a.mp4"-->

    </div>

    <div class="TopSitesCommon">
      <h1>HoHux</h1>
      <!--      <h2>万物互联-多协议互联互通</h2>-->
      <h2>一个简单 高效 安全的物联网系统</h2>
      <!--   从产品到生产，将物联网聚集在一个应用程序中。更快地构建安全的物联网应用，实现任何设备任何协议互联互通。   -->
      <h3>
        从产品到生产，更快地构建安全的物联网应用，实现任何设备任何协议互联互通。
      </h3>

      <div class="banner-start">
        <a type="primary" href="https://console.hohux.com/">
          快速开始
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner"
}
</script>

<style scoped>
.backgroundImage {
  height: 80vh;
  width: 100%;
}

.video {
  height: 80vh;
  margin: 0;
  object-fit: cover;
  padding: 0;
  position: absolute;
  width: 100%;
}

.TopSitesCommon {
  display: grid;
  justify-items: center;
  position: absolute;
  top: 32%;
  width: 100%;
}

.TopSitesCommon h1 {
  color: #ffffff;
  font-size: 60px;
  /*font-size: 3rem;*/
}

.TopSitesCommon h2 {
  color: #ffffff;
  font-size: 52px;
  /*font-size: 2.5rem;*/
}


h3 {
  font-weight: 500;
  font-size: 22px;
  color: #FFFFFF;
  line-height: 26px;
}

.TopSitesCommon .banner-start {
  margin: 1.8rem auto;
  height: 60px;
  width: 150px;
  text-align: center;
  line-height: 60px;
  background: #0073ec;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  border-width: 2px;
  cursor:pointer
}

.TopSitesCommon .banner-start a {
  font-size: 18px;
  color: #FFFFFF;
}
</style>
