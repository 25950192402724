<template>
  <div class="solution">

    <h2>应用场景，无限可能</h2>

    <a-row :gutter="16">

      <a-col :span="6" v-for="item in solution" :key="item.key" style="padding-top:20px;">
        <a-card hoverable :bordered="false">
          <template #cover>
            <!--              <a-image :height="300" v-lazy="item.src"/>-->
            <img v-lazy="item.src" :alt="item.title">
          </template>
          <a-card-meta>
            <template #title>
              <h3> {{ item.title }}</h3>
            </template>

            <template #description>
              <p>
                {{ item.description }}
              </p>
            </template>
          </a-card-meta>
        </a-card>
      </a-col>

    </a-row>
  </div>
</template>

<script>
import i5999821 from '@/assets/img/home/5999821.png'
import i5699851 from '@/assets/img/home/5699851.png'
import i2562971 from '@/assets/img/home/2562971.png'
import i2249241 from '@/assets/img/home/2249241.png'
import i2802321 from '@/assets/img/home/2802321.png'
import i7089013 from '@/assets/img/home/7089013.jpg'
import i2541781 from '@/assets/img/home/2541781.png'
import i21356771 from '@/assets/img/home/21356771.png'

export default {
  name: "Solution",
  data() {
    return {
      solution: [
        {
          key: 1,
          src: i5999821,
          title: "智慧城市",
          description: "智慧城市是把新一代信息技术充分运用在城市中各行各业基于知识社会下一代创新的城市信息化高级形态，实现信息化、工业化与城镇化深度融合，提高城镇化质量，实现精细化和动态管理，并提升城市管理成效和改善市民生活质量。"
        },
        {
          key: 3,
          src: i5699851,
          title: "智慧交通",
          description: "智慧交通又称智能运输，是将先进的科学技术有效地综合运用于交通运输、服务控制和车辆制造，加强车辆、道路、使用者三者之间的联系，从而形成一种保障安全、提高效率、改善环境、节约能源的综合运输系统 。"
        },
        {
          key: 8,
          src: i2562971,
          title: "智慧制造",
          description: "智能制造应当包含智能制造技术和智能制造系统，智能制造系统不仅能够在实践中不断地充实知识库，而且还具有自学习功能，还有搜集与理解环境信息和自身的信息，并进行分析判断和规划自身行为的能力。"
        },
        {
          key: 7,
          src: i2249241,
          title: "新基建",
          description: "新基建是智慧经济时代贯彻新发展理念，实现国家生态化、数字化、智能化、高速化、新旧动能转换与经济结构对称态，建立现代化经济体系的国家基本建设与基础设施建设，包括物联网基础设施建设、新型城镇化基础设施建设等。"
        },
        {
          key: 2,
          src: i2802321,
          title: "智能家居",
          description: "智能家居是以住宅为平台，利用物联网技术、综合布线技术、网络通信技术、 安全防范技术、自动控制技术将家居生活有关的设施集成，构建高效的住宅设施与家庭日程事务的管理系统，提升家居安全性、便利性、舒适性、环保节能的居住环境。"
        },
        {
          key: 6,
          src: i7089013,
          title: "智慧医疗",
          description: "智慧医疗英文简称WITMED，通过物联网技术，能有效地帮助医院实现智能化管理。通过传感器对人的生理状态进行捕捉，将他们记录到电子健康文件中，方便个人或医生查阅。通过RFID技术对医疗物品进行监控与管理，实现医疗设备、用品可视化。"
        },
        {
          key: 4,
          src: i2541781,
          title: "环境监测",
          description: "环境监测，是科学管理环境和环境执法监督的基础，是环境保护必不可少的基础性工作。环境监测的核心目标是提供环境质量现状及变化趋势的数据，判断环境质量，评价当前主要环境问题，为环境管理服务。"
        },
        {
          key: 5,
          src: i21356771,
          title: "智慧农业",
          description: "智慧农业利用物联网技术对农业生产进行控制，使传统农业更具有“智慧”。除了精准感知、控制与决策管理外，从广泛意义上讲，智慧农业还包括农业电子商务、食品溯源防伪、农业休闲旅游、农业信息服务等方面的内容。"
        },
      ]
    }
  }
}
</script>

<style scoped>
.solution {
  background-color: #ececec;
  padding: 20px;
  text-align: center;
}


h2 {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 0.5em;
  font-size: 48px;
  color: #262626;
  line-height: 56px;
  font-weight: 700;
}


h3 {
  font-weight: 600;
  font-size: 22px;
  color: #262626;
  line-height: 26px;
}

p {
  margin: 15px 0 0 0;
  font-size: 18px;
  color: #697A89;
  letter-spacing: 0;
  line-height: 28px;
  max-width: 532px;
  text-align: left;
}
</style>
