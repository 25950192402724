<template>
  <a-row>
    <a-col :span="18" :offset="3" style="margin: 0 auto">


      <a-breadcrumb style="margin-top: 20px;margin-bottom: 20px">
        <a-breadcrumb-item>
          <router-link to="/contact/wish">聆听建议</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ pageData.info.title }}</a-breadcrumb-item>
      </a-breadcrumb>


      <a-card :title="pageData.info.title">
        <template #extra>
          <a-button type="link" @click="likeWishInfo">
            <like-outlined/>
            点赞
          </a-button>

        </template>

        <a-textarea v-model:value="pageData.info.remark" placeholder="Basic usage" autoSize disabled
                    style="color: #1e1e1e"/>

        <div style="margin-top: 20px;float: right">
          <p>
            <clock-circle-outlined/>
            {{ pageData.info.gmtCreate }}
          </p>
        </div>

      </a-card>

    </a-col>
  </a-row>
</template>

<script>
import {useRouter} from "vue-router";
import {onMounted, reactive} from "vue";
import {connectWishInfo, likeWish} from "@/api/connect";
import {ClockCircleOutlined, LikeOutlined} from "@ant-design/icons-vue";
import { message } from 'ant-design-vue';

export default {
  name: "WishDetail",
  components: {
    LikeOutlined,
    ClockCircleOutlined
  },
  setup() {

    const router = useRouter()
    const wishCode = router.currentRoute.value.params.id;

    let pageData = reactive({
      info: {},
    });

    const getData = () => {
      connectWishInfo(wishCode)
          .then((response) => {
            pageData.info = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const likeWishInfo = () => {
      likeWish(wishCode)
          .then((response) => {
            if (response.data.data){
              message.success('点赞成功');
            }else {
              message.error('点赞失败');
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    onMounted(() => {
      getData();
    })

    return {
      pageData,
      likeWishInfo
    }
  }
}
</script>

<style scoped>

</style>
