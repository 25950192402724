<template>
  <a-layout-footer class="footer">

    <a-row type="flex" justify="center" class="footer-wrap">
      <a-col :span="6">
        <h2>学习</h2>
        <div><a href="https://docs.hohux.com/" target="_blank">产品文档</a></div>
        <div>
          <router-link to="/contact/wish/create">新需求</router-link>
        </div>
      </a-col>
      <a-col :span="6">
        <h2>资源链接</h2>
        <div>
          <router-link to="/plugins">插件市场</router-link>
        </div>
        <div>
          <router-link to="/developer/tools">开发者工具</router-link>
        </div>
        <div>
          <router-link to="/contact/wish">需求建议</router-link>
        </div>
      </a-col>
      <a-col :span="6">
        <h2>社区</h2>
        <div><a href="https://docs.hohux.com/" target="_blank">更新记录</a></div>
        <div>
          <router-link to="/contact/wish">常见问题</router-link>
        </div>
        <div>
          <router-link to="/contact/wish/create">报告 Bug</router-link>
        </div>
      </a-col>
      <a-col :span="6">
        <h2>HoHux</h2>
        <div>
          <router-link to="/contact">联系我们</router-link>
        </div>
      </a-col>
    </a-row>

    <div class="footer-bar">
      <span><a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备19055881号-3</a></span>
      Copyright © hohux.com
    </div>

  </a-layout-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer {
  text-align: center;
  padding-top: 20px;
  background-color: #000000;
  color: hsla(0, 0%, 100%, .65);
}

.footer span {
  padding-right: 20px
}

.footer a {
  color: hsla(0, 0%, 100%, .65);
}

.footer-wrap {
  padding: 86px 24px 93px;
  border-bottom: 1px solid hsla(0, 0%, 100%, .25);
}

.footer-wrap h2 {
  font-size: 16px;
  margin: 0 auto 24px;
  font-weight: 500;
  position: relative;
  color: hsla(0, 0%, 100%, .9);
}

.footer-wrap a {
  color: hsla(0, 0%, 100%, .9);
}

.footer-wrap div {
  margin: 12px 0;
  color: hsla(0, 0%, 100%, .9);
}

.footer-bar {
  float: right;
  margin-right: 200px;
  padding-top: 18px;
}
</style>
