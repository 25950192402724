<template>
  <div style="background-color: #f5f5f5">

    <a-row>
      <a-col :span="12" :offset="6">

        <!--        <a-breadcrumb style="margin-top: 20px">-->
        <!--          <a-breadcrumb-item>-->
        <!--            <router-link to="/contact/wish">首页</router-link>-->
        <!--          </a-breadcrumb-item>-->
        <!--          <a-breadcrumb-item>新建议</a-breadcrumb-item>-->
        <!--        </a-breadcrumb>-->

        <!--        <div>-->
        <!--          技术支持、系统故障、咨询服务、投诉建议，请您通过智能在线或者拨打售后咨询热线 400-80-13260 向阿里云工程师寻求支持。-->
        <!--        </div>-->

        <div class="connect-form">

          <h2>新建议</h2>

          <a-form
              :model="formState"
              name="basic"
              layout="vertical"
              autocomplete="off"
              @finish="onFinish"
              @finishFailed="onFinishFailed"
          >
            <a-form-item
                label="标题"
                name="title"
                :rules="[{ required: true, message: '标题输入限制50个字符' }]"
            >
              <a-input v-model:value="formState.title" placeholder="标题输入限制50个字符" size="large"/>
            </a-form-item>

            <a-form-item
                label="标签"
                name="type"
                :rules="[{ required: true, message: '请输入标签' }]"
            >
              <a-input v-model:value="formState.type" placeholder="推荐标签：插件、设备管理、设备预警、产品管理" size="large"/>
            </a-form-item>

            <a-form-item
                label="详细内容"
                name="remark"
                :rules="[{ required: true, message: '请描述需求场景和改进建议' }]"
            >
              <a-textarea v-model:value="formState.remark" placeholder="请描述需求场景和改进建议" :rows="8"/>
            </a-form-item>

            <a-form-item name="filePath" label="上传附件" extra="选择文件,允许的文件类型：png，jpg，gif，jpeg，pdf，doc且不超过5MB">
              <a-upload
                  v-model:fileList="fileState.upload"
                  name="file"
                  action="/api/file-service/file"
                  :headers="headers"
                  list-type="picture"
                  :before-upload="beforeUpload"
                  @change="uploadChange"
              >
                <a-button>
                  <template #icon>
                    <UploadOutlined/>
                  </template>
                  上传附件
                </a-button>
              </a-upload>
            </a-form-item>

            <a-form-item name="display" label="是否公开">
              <a-radio-group v-model:value="formState.display">
                <a-radio value="0">是</a-radio>
                <a-radio value="1">否</a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item>
              <a-button type="primary" html-type="submit" size="large">立即提交</a-button>
            </a-form-item>
          </a-form>
        </div>

      </a-col>
    </a-row>

  </div>
</template>

<script>
import {reactive} from "vue";
import {saveConnectWish} from "@/api/connect";
import {message} from "ant-design-vue";
import {UploadOutlined} from '@ant-design/icons-vue';
import cookies from "js-cookie";
import {ACCESS_TOKEN} from "@/store/mutation-types";

export default {
  name: "CreateWish",
  components: {
    UploadOutlined,
  },
  setup() {

    const fileState = reactive({
      upload: [],
    });

    const formState = reactive({
      title: '',
      type: '',
      remark: '【需求场景】 \r\r\r\r 【改进建议】\r',
      filePath: [],
      display: '0',
    });

    const headers = {
      Authorization: "Bearer " + cookies.get(ACCESS_TOKEN),
    };

    const uploadChange = (info) => {
      if (info.file.status === "uploading") {
        // this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        formState.filePath.push(info.file.response.url)
      }
    };

    const beforeUpload = (file) => {

      // const isFileType = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpeg" || file.type === "application/pdf" || file.type === "doc";
      // if (!isFileType) {
      //   message.error("请上去正确的文件格式!");
      // }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error("上传文件大小不超过 5MB!");
      }
      // return isFileType && isLt2M;
      return isLt2M;
    };

    const onFinish = values => {
      values.filePath = values.filePath.toString()
      saveConnectWish(values)
          .then((response) => {
            if (response.data) {
              message.success('提交成功');
              location.reload()
            } else {
              message.error('提交失败，请重试');
            }
          })
          .catch((error) => {
            console.log(error);
          });
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      fileState,
      formState,
      headers,
      beforeUpload,
      uploadChange,
      onFinish,
      onFinishFailed,
    };
  },
}
</script>

<style scoped>
img {
  width: 100%;
}

.connect-form {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
}

.connect-form h2 {
  text-align: center;
}
</style>
