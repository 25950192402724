<template>
  <a-layout-header>
    <div class="logo">
      <!--      <span><a href="https://www.hohux.com">HoHux</a></span>-->
      <router-link to="/">
        <span>HoHux</span>
      </router-link>
    </div>

    <div style="float: right">

      <a-menu
          theme="light"
          mode="horizontal"
          :inlineCollapsed="false"
          :style="{ lineHeight: '64px' }"
      >

        <a-menu-item key="/plugins">
          <router-link to="/plugins">插件市场</router-link>
        </a-menu-item>

        <a-menu-item key="docs">
          <a href="https://docs.hohux.com" target="_blank" rel="noopener noreferrer">
            产品文档
          </a>
        </a-menu-item>

        <a-menu-item key="/contact/wish">
          <router-link to="/contact/wish">需求建议</router-link>
        </a-menu-item>

        <a-menu-item key="/developer/tools">
          <router-link to="/developer/tools">开发者工具</router-link>
        </a-menu-item>

        <a-menu-item key="/contact">
          <router-link to="/contact">联系我们</router-link>
        </a-menu-item>

        <template v-if="isLogin">

          <a-menu-item key="/console">
            <a href="https://console.hohux.com/" target="_blank" rel="noopener noreferrer">
              控制台
            </a>
          </a-menu-item>

          <a-sub-menu key="sub1">
            <template #title>
              <a-avatar :src="info.avatar" style="color: #ffffff; background-color: #1890ff"/>
              {{ info.nikeName }}
            </template>
            <a-menu-item key="setting:1" @click="logout">退出登录</a-menu-item>
          </a-sub-menu>

        </template>
        <template v-if="!isLogin">
          <a-menu-item key="login">
            <a href="https://console.hohux.com/login">
              <user-outlined/>
              登录
            </a>
          </a-menu-item>

          <a-menu-item key="register">
            <a href="https://console.hohux.com/register">
              立即注册
            </a>
          </a-menu-item>
        </template>

      </a-menu>

    </div>

  </a-layout-header>
</template>

<script>
import {onMounted, ref} from "vue";
import {basicUserInfo, userLogout} from "@/api/user";
import {authLogout} from "@/utils/auth";
import {ACCESS_TOKEN, BASIC_INFO} from "@/store/mutation-types";
import ls from "@/utils/ls";
import cookies from "js-cookie";
import {UserOutlined} from '@ant-design/icons-vue';

export default {
  name: "Header",
  components: {UserOutlined},
  setup() {

    let t = cookies.get(ACCESS_TOKEN)
    let isLogin = !!t;

    let info = ref(ls.get(BASIC_INFO));

    // 退出登录
    const logout = () => {
      userLogout().then(() => {
        authLogout()
        location.href = "https://www.hohux.com";
      })
    };

    // 获取用户数据
    const getUserInfo = () => {
      basicUserInfo().then((response) => {
        info.value = response.data;
        ls.set(BASIC_INFO, response.data);
      }).catch((error) => {
        console.log(error);
      });
    }

    onMounted(() => {
      if (isLogin) {
        getUserInfo();
      }
    })

    return {
      selectedKeys: ref(["2"]),
      info: info,
      logout,
      isLogin
    };
  },
  watch: {
    $route: function (to) {
      this.selectedKeys = [to.path];
    },
  },
};
</script>

<style scoped>
.ant-layout-header {
  background: #ffffff;
  border-bottom: 1px solid #e8ecf6;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  font-size: 32px;
  color: #1890ff;
  text-align: center;
  line-height: 32px;
  font-family: UR, serif;
  /*background: rgba(255, 255, 255, 0.3);*/
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.logo a {
  color: #1890ff;
}

.logo a:hover {
  color: #1890ff;
}

.user-r {
  float: right;
  margin-right: 2em;
}
</style>
