import {createApp} from 'vue'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import index from './route/index'
import {authLogout} from "@/utils/auth";
import cookies from "js-cookie";
import {ACCESS_TOKEN} from "@/store/mutation-types";
import { message } from 'ant-design-vue';

import VueLazyload from 'vue-lazyload'

// 请求拦截器
axios.interceptors.request.use(function (config) {
    const token = cookies.get(ACCESS_TOKEN)
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.defaults.baseURL = "/api"

// 响应拦截器
axios.interceptors.response.use(function (response) {
    if (response.data.code === 4031) {
        authLogout()
    }
    return response;
}, function (error) {
    if (window.location.href.indexOf("/login") !== -1) {
        // 返回错误信息
        return Promise.reject(error);
    }

    switch (error.response.status) {
        case 401:
            authLogout()
            location.href = "https://console.hohux.com/login";
            break;

        case 413:
            message.error('上传失败');
            break;

        // case 404:
        //     console.log('404')
        //     router.push({path: '/404'}).then(r => console.log(r))
        //     break;
    }
    // 返回错误信息
    return Promise.reject(error);
});

createApp(App)
    .use(Antd)
    .use(index)
    .use(VueLazyload)
    .use(VueAxios, axios)
    .mount('#app')
