import axios from "axios";

const userApi = {
    Login: '/oauth-service/oauth/token',
    Logout: '/oauth-service/auth/logout',
    UserInfo: '/user',
    BasicUserInfo: '/oauth-service/user/basicUserInfo',
    UserMenu: '/user-service/permission/tree',
    Register: '/user-service/user/register',
    Activate: '/user-service/user/activate',
    RetrievePasswordSendEmail: '/user-service/user/retrieve/send',
    ChangePassword: '/user-service/user/change/password',
}

export function userLogout() {
    return axios({
        method: 'get',
        url: userApi.Logout
    });
}


export function basicUserInfo() {
    return axios({
        method: 'get',
        url: userApi.BasicUserInfo
    });
}
