import {createRouter, createWebHistory} from 'vue-router'
import Detail from "@/views/plugin/Detail";
import Privacy from "@/views/terms/privacy/Privacy20210601";
import Service from "@/views/terms/service/Service20210601";
import {APP_DESCRIPTION, APP_KEYWORDS, APP_NAME, APP_TITLE} from "@/store/mutation-types";
import Contact from "@/views/connect/Index";
import Wish from "@/views/connect/wish/Index";
import CreateWish from "@/views/connect/wish/Create";
import DetailWish from "@/views/connect/wish/Detail";
import Home from "@/views/Home";
// import Home from "@/views/Home";

// 1. 定义路由组件.
// 也可以从其他文件导入
const About = {template: '<div>About</div>'}

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: '物联网平台-物联网系统-万物互联-多协议互联互通',
            content: {
                keywords: APP_KEYWORDS,
                description: APP_DESCRIPTION
            }
        },
        component: Home
    },
    {path: '/about', name: 'about', meta: {title: '关于我们'}, component: About},
    {path: '/contact', name: 'contact', meta: {title: '联系我们'}, component: Contact},
    {path: '/contact/wish', name: 'wish', meta: {title: '聆听建议'}, component: Wish},
    {path: '/contact/wish/create', name: 'createWish', meta: {title: '新建议'}, component: CreateWish},
    {path: '/contact/wish/detail/:id', name: 'wishDetail', meta: {title: '聆听建议'}, component: DetailWish},
    {path: '/terms/privacy', name: 'Privacy20210601', meta: {title: '隐私协议'}, component: Privacy},
    {path: '/terms/service', name: 'Service20210601', meta: {title: '服务条款'}, component: Service},
    {path: '/plugins', name: 'plugins', meta: {title: '插件市场'}, component: () => import("../views/plugin/Index")},
    {path: '/plugins/particular/:id', name: 'pluginsDetail', meta: {title: '插件市场'}, component: Detail},
    {
        path: '/developer/tools',
        name: 'download',
        meta: {
            title: '开发者工具',
            content: {
                keywords: 'HoHux,物联网开发工具',
                description: APP_DESCRIPTION
            }
        },
        component: () => import("../views/developer/Tools")
    },
]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const index = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes, // `routes: routes` 的缩写
})

index.beforeEach((to, from, next) => {
    // 设置title
    if (to.meta.title) {
        document.title = APP_NAME + to.meta.title;
    } else {
        document.title = APP_NAME + APP_TITLE;
    }
    // keywords、description
    if (to.meta.content) {
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords);
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description);
    } else {
        document.querySelector('meta[name="keywords"]').setAttribute('content', APP_KEYWORDS);
        document.querySelector('meta[name="description"]').setAttribute('content', APP_DESCRIPTION);
    }

    next();
})

export default index
