export const ACCESS_TOKEN = 'AccessToken'
export const ACCESS_ROLES = 'AccessRoles'
export const NICKNAME = 'nickname'
export const USERCODE = 'usercode'
export const EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000
export const EXPIRATION_COOKIE_TIME = 7
export const MENU = 'menu'
export const BASIC_INFO = 'basicInfo'

export const APP_NAME = 'HoHux-'
export const APP_TITLE = '物联网平台-物联网系统-万物互联-多协议互联互通-物联网产品'
export const APP_KEYWORDS = 'HoHux,物联网系统,物联网产品,物联网平台,物联网应用'
export const APP_DESCRIPTION = 'HoHux简单、快速、可靠、安全、免费、多协议互联互通的物联网系统'
// export const APP_NAME = '-HoHux-物联网平台-物联网系统-万物互联-多协议互联互通'

export const SIDEBAR_TYPE = 'sidebar_type'
export const TOGGLE_MOBILE_TYPE = 'is_mobile'
export const TOGGLE_NAV_THEME = 'nav_theme'
export const TOGGLE_LAYOUT = 'layout'
export const TOGGLE_FIXED_HEADER = 'fixed_header'
export const TOGGLE_FIXED_SIDEBAR = 'fixed_sidebar'
export const TOGGLE_CONTENT_WIDTH = 'content_width'
export const TOGGLE_HIDE_HEADER = 'auto_hide_header'
export const TOGGLE_COLOR = 'color'
export const TOGGLE_WEAK = 'weak'
export const TOGGLE_MULTI_TAB = 'multi_tab'
export const APP_LANGUAGE = 'app_language'

export const CONTENT_WIDTH_TYPE = {
    Fluid: 'Fluid',
    Fixed: 'Fixed'
}

export const NAV_THEME = {
    LIGHT: 'light',
    DARK: 'dark'
}

export const PATTERN_PHONE = {
    PATTERN: '^(13[0-9]|14[5|6|7|8|9]|15[0|1|2|3|5|6|7|8|9]|16[6]|17[3|5|6|7|8]|18[0|1|2|3|5|6|7|8|9]|19[1|8|9])\\d{8}$',
    MSG: '手机号码错误'
}
export const PATTERN_EMAIL = {
    PATTERN: '^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$',
    MSG: '邮箱格式错误'
}

export const PATTERN_PASSWORD = {
    PATTERN: '^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,20}$',
    MSG: '密码长度6到20位，包含数字,英文,字符中的两种以上'
}

export const PATTERN_DICT_TAG_CODE = {
    PATTERN: '^[A-Za-z][0-9A-Za-z]+$',
    MSG: '编码可以由字母和数字组成，以字母开头，不能少于2位字符'
}
