<template>
  <a-row>
    <a-col :span="18" :offset="3" style="margin-top: 30px">

      <h1>HoHux 隐私协议</h1>

      <p>版本生效日期：2021 年 6 月 1 日</p>

      <h2>引言</h2>

      <p>本《HoHux 隐私政策》（以下简称“本政策”）适用于青岛亿云物联科技有限公司（以下简称“我们”或“HoHux”）通过其运营的服务平台（包括但不限于网址为 www.hohux.com
        的网站、微信公众号、小程序、APP、云服务平台等，以下统称“HoHux 服务平台”）为用户（以下简称“您”）提供的功能、产品及/或服务。我们在此提醒您：在使用 HoHux
        服务平台的各项功能、产品及/或服务（以下统称“本服务”）前，请您务必仔细阅读并充分理解本政策的含义及内容。您一经点击确认或选择使用本服务或以其他方式选择接受本政策即表示您已充分阅读、准确理解并接受本政策的全部内容，并与
        HoHux 达成一致。如您对本政策内容有任何疑问、意见或建议，您可通过 HoHux 官方网站“<a href="https://home.hohux.com/contact">联系我们</a>”与我们联系。</p>

      <p>我们将通过本政策向您说明我们如何收集、使用、存储和对外提供您的个人信息，以及您如何管理您的个人信息等事宜。</p>

      <p>您也可以通过 HoHux 官方网站 <a href="https://home.hohux.com/terms/privacy">https://home.hohux.com/terms/privacy</a> 下载本政策的副本。</p>

      <p>
        本政策与您使用本服务所包括的各种业务功能息息相关，我们将努力采取清晰易懂、简明通俗的表述，并以显着方式提示您注意并理解本政策中与您有重大利害关系的相关条款及个人信息。我们建议您在使用我们的服务前仔细阅读并确认您已充分理解本政策（特别是采用加粗、斜体、下划线等方式提示您注意的内容），并做出您认为适当的选择。</p>

      <h2>目录</h2>

      <ul>
        <li>一、我们如何收集您的个人信息</li>
        <li>二、我们如何使用 Cookies 和同类技术</li>
        <li>三、我们如何存储和保护您的个人信息</li>
        <li>四、我们如何使用您的个人信息</li>
        <li>五、我们如何对外提供您的个人信息</li>
        <li>六、您如何管理您的个人信息</li>
        <li>七、我们如何保护未成年人的权益</li>
        <li>八、本政策如何更新</li>
        <li>九、联系我们</li>
        <li>十、适用范围</li>
      </ul>

      <h2>一、我们如何收集您的个人信息</h2>

      <p>
        我们遵循合法、正当、必要原则，在本政策所述目的、方式和范围内收集、使用您的个人信息。如我们要将您的个人信息用于本政策未载明的用途，或将基于特定目的收集而来的个人信息用于其他目的，我们将以合理的方式向您告知，并在使用前征得您的同意。
        请您注意，如果您提供的为非您本人的个人信息，请您确保已取得相关主体的授权同意。</p>

      <ol>
        <li><p><strong>收集个人信息的情形</strong></p>
          <p>您在注册 HoHux
            服务平台账户、使用我们的服务以及您在使用我们的服务过程中为获得技术支持、协助、交互服务、社区讨论等可能向我们主动提供您的个人信息。为了向您提供我们的服务，我们依据本政策可能会收集您主动提供的个人信息及其他我们主动收集的个人信息。具体如下：</p>
          <ol>
            <li><p>当您注册、创建、登录 HoHux 帐户或为了遵守法律法规对实名制的管理要求时，我们可能会收集您的姓名、电子邮件地址、电话号码、微信账号。</p></li>
            <li>当您使用 HoHux 服务平台寻求产品咨询及/或服务支持、填写我们的在线表格或与我们的支持团队沟通时，为了更好地解决及回应您的需求并与您取得联系，我们可能会收集您的姓名、公司、电子邮件地址、电话号码。
            </li>
            <li>当您使用 HoHux 服务平台提供的社区讨论服务（如发表评论、参与话题讨论），我们可能会收集您的邮箱地址、微信账号、移动电话号码、您的评论信息。</li>
            <li>当您在使用我们的服务过程中，为了接收并记录的您使用我们的服务的情况、提高您使用我们服务的用户体验、适配性及安全性，我们可能会收集您的设备型号、设备标识符、MAC 地址、IP
              地址、无线连接信息、操作系统类型和版本、浏览器类型和版本、移动网络信息、设备位置及地区信息，以及您访问、点击、下载、发送/接收消息和其他使用 HoHux 服务平台相关的日志信息。
            </li>
            <li><p>
              为了提升您的服务体验及改进服务质量，或者为您推荐更优质、更适合的个性化服务，我们会收集您使用我们服务的操作记录、您与客户服务团队联系时提供的信息及您参与问卷调查时向我们发送的信息。如您不同意我们收集该等信息，不影响您使用我们提供的其他功能。</p>
              <p>
                如我们为您提供的服务需要从第三方间接获取您的个人信息，我们会要求第三方说明您的个人信息来源，对合法性进行确认，并确认提供方已获得的个人信息处理的授权同意范围，包括使用目的，以及您是否授权同意转让、共享、公开披露等。如我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权同意范围，我们将在处理您的该等个人信息前，征得您的明示同意，法律法规另有规定的除外。</p>
            </li>
          </ol>
        </li>
        <li><p><strong>无需征得您的同意收集、使用个人信息的情形</strong></p>
          <p><strong>根据相关法律法规及国家标准，在以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意：</strong></p>
          <ol>
            <li><p>与我们履行法律法规规定的义务相关的。</p></li>
            <li>与国家安全、国防安全直接相关的。</li>
            <li>与公共安全、公共卫生、重大公共利益直接相关的。</li>
            <li>与刑事侦查、起诉、审判和判决执行等直接相关的。</li>
            <li>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。</li>
            <li>所涉及的个人信息是您自行向社会公众公开的信息。</li>
            <li>根据您的要求签订和履行合同所必需的。</li>
            <li>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</li>
            <li>维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障，识别、处置金融产品或服务中的欺诈或被盗用。</li>
            <li>法律法规规定的其他无需征得您同意的情形。</li>
          </ol>
        </li>
      </ol>

      <h2>二、我们如何使用 Cookies 和同类技术</h2>

      <ol>
        <li>当您使用 HoHux 服务平台及本服务时，我们可能会保存您的登录状态并且为您分配一个或多个名为 Cookie
          的小数据文件，例如：当您访问一个需要您登录才可以提供的信息或服务，当您登录时，我们会把该您的登录名加密存储在您计算机的 Cookie
          文件中，由于是不可逆转的加密存储，其他人即使可以使用该您的计算机，也无法识别出您的登录名。您并不需要额外做任何工作，所有的收集、保存和加密工作都由系统自动完成。
        </li>
        <li>Cookie 文件将保存在您的计算机硬盘或设备终端的闪存上，它只能被设置它们的服务器阅读，而且不能执行任何代码或病毒。您可以随时使用浏览器或操作系统软件将其删除。</li>
        <li>启用 Cookie 的目的为：改善用户体验，借助于
          Cookie，网站能够记住您的单次访问或多次访问，以及网站能够保存设置，这意味着您无需每次访问时重新配置用户偏好设置；以及广告优化，我们可能会根据您访问相关网站的统计信息为您显示您可能感兴趣的产品或广告信息。这些统计信息并不包括您的任何个人信息，这些信息可能被用来评估广告的有效度。Cookie
          等技术有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。
        </li>
        <li>HoHux 不会将 Cookie 用于本政策所述目的之外的其他用途。您可根据自己的偏好管理或删除 Cookie。在您访问我们的官方网站时，您也可以通过浏览器选择“不使用 Cookie”或“在使用 Cookie
          时事先通知我”的选项禁止 Cookie 的产生，但是您如选择这样做可能会影响您对 HoHux 服务平台的使用，或是您无法享受到更加安全、快捷、顺畅、个性化的服务，但不影响您正常使用我们提供的其他服务。
        </li>
        <li>请您注意，您访问的第三方网站经营者、通过 HoHux 服务平台接入的第三方服务和由 HoHux
          处接收您的个人信息的第三方可能有自己的隐私政策；当您查看第三方创建的网页或者使用第三方开发的应用程序时，这些第三方可能会放置他们自己的 Cookie。这些第三方通过 Cookie
          收集和使用个人信息的行为，不受我们的控制，也不受本政策约束，而是受该第三方的隐私政策约束。我们会努力要求这些第三方对您的个人信息采取保护措施，我们建议您在使用第三方通过 Cookie
          提供的服务前，应仔细阅读并自行决定是否受该第三方的隐私政策约束。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。
        </li>
      </ol>

      <h2>三、我们如何存储和保护您的个人信息</h2>

      <ol>
        <li>存储地点和期限
          <ol>
            <li>
              我们将会按照法律法规规定，将中华人民共和国境内收集的您的个人信息存储于中华人民共和国境内，并依法对这些信息进行严格保密。如部分产品涉及跨境业务，我们需要向境外机构传输境内收集的相关用户个人信息的，我们会按照法律、行政法规和相关监管部门的规定执行，向您告知出境的目的、涉及的个人信息类型、接收方的身份和数据安全能力及可能存在的安全风险且征得您的同意，并通过签订协议、监督核查、安全审计等措施明确各方义务和责任，要求境外机构为所获得的个人信息保密。我们也会按照法律、行政法规和相关标准的规定执行，例如事先开展安全评估。
            </li>
            <li>一般而言，我们仅为实现收集目的所必需的最短时间和法律法规及监管要求的期限内保留您的个人信息。在超出保存期限后，我们会将您的个人信息删除或匿名化。</li>
            <li>
              如果我们终止服务或运营，我们会提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，但国家法律法规、规章、规范性文件或政府的政策、命令等另有要求或为履行我们的合规义务而保留您的个人信息的除外。为免疑义，在本服务停止运营时，我们对您的个人信息删除或匿名化处理并不影响我们的关联方基于您的同意而继续处理您的个人信息以及您届时相应地继续使用该等关联方的服务。
            </li>
          </ol>
        </li>
        <li>信息安全
          <ol>
            <li>
              为了保障您的信息安全，我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全，采取技术手段和管理安全措施来降低丢失、误用、非授权访问、披露和更改的风险。其中，技术手段包括但不限于传输层数据加密（例如，TLS、SSL）、防火墙和加密存储、物理访问控制以及信息访问授权控制；我们也建立了相关的制度、流程和组织以通过管理措施保障信息的安全。
            </li>
            <li>若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。同时，我们还将按照法律法规的要求或监管部门要求上报个人信息安全事件的处置情况。</li>
            <li>在使用 HoHux 服务平台时，请妥善保管好您的账户及其密码以及其他身份认证要素，我们会通过您的账户及其密码或其他身份认证要素来识别您的身份。一旦您泄漏了 HoHux
              服务平台账户及其密码或其他身份认证要素，您可能会丢失您的信息，并可能产生对您不利的法律后果。如您发现 HoHux
              服务平台及其密码或其他身份认证要素因任何原因已经或将受到泄漏时，您应该立即和我们取得联系，以便我们及时采取相应措施以防止或减少相关损失。
            </li>
          </ol>
        </li>
      </ol>

      <h2>四、我们如何使用您的个人信息</h2>

      <p>我们可能将收集到的您的相关信息用作下列用途：</p>

      <ol>
        <li>向您提供我们的服务（包括前述“<strong>一、我们如何收集您的个人信息</strong>”中描述的服务）。</li>
        <li>根据国家相关主管部门的要求向相关部门报告。</li>
        <li>在您使用我们的服务时，我们将您的信息用于身份验证、客户服务、安全防范、诈骗/盗用/可疑交易监测、预防或禁止非法活动、降低风险及防止风险传导、存档和备份用途，确保我们向您提供的产品和服务的安全性。</li>
        <li>
          采取脱敏、去标识化或匿名化方式对您的个人信息等进行综合统计、分析、加工或其他处理（请您了解，在对个人信息匿名化处理后，将无法通过匿名化后的信息识别到个人信息主体，因此不再属于个人信息），以便为您提供更准确、便捷的服务或帮助我们评估、改善、设计服务及运营活动、为您提供服务状态通知、查询等功能，并持续维护、改善这些功能。若您认为前述服务方式或运营活动对您造成了打扰，关闭或退订方式可见本政策“第六部分
          改变您的授权同意范围”。
        </li>
        <li>为了使您了解我们服务的具体情况，我们会通过 HoHux
          服务平台、电话、邮件、手机短信、微信公众号、客户端消息等一种或多种方式向您发送服务状态的通知、进行营销宣传或发送其他商业性电子信息或邀请您参与有关我们产品和服务的调查。若您认为前述方式对您造成了打扰，请按照通知或消息中提示的方式退订或关闭，具体也可见本政策“第六部分
          改变您的授权同意范围”。
        </li>
        <li>为了维护您或我们的权益，或者为了解决与您相关的争议。</li>
        <li>经您<strong>授权的其他用途</strong>。如我们要将您的个人信息用于本政策未载明的其他用途或者超出收集您个人信息所称的目的具有直接或合理关联的范围，我们会在再次使用您个人信息前，按照法律法规及国家标准的要求以点击确认协议、具体场景下的点击确认动作或弹窗提示等形式再次征求您的同意。
        </li>
      </ol>

      <h2>五、我们如何对外提供您的个人信息</h2>

      <ol>
        <li><p><strong>委托处理</strong></p>
          <p>
            为了提升信息处理的质量与效率，降低信息处理的成本，我们可能会委托有能力的合作机构代表我们来处理信息。我们会遵守法律法规关于委托处理的相关规定，充分审查，评估此类合作机构保护个人信息的能力，通过书面协议、审计等方式要求合作机构遵守严格的保密义务，采取技术方式对委托处理的个人信息进行脱敏处理，并禁止其将这些信息用于未经您授权的用途或者符合法律法规的规定。在委托关系解除时，我们会要求合作机构不再保存相关信息。</p>
        </li>
        <li><p><strong>向第三方共享</strong></p>
          <p>除非获得您的授权同意或属于“共享、转让、公开披露个人信息时事先征得授权同意的例外”，否则我们不会将您的个人信息共享给第三方（以下简称“<strong>第三方</strong>”）。我们仅在以下情形中与第三方共享您的个人信息，第三方包括我们的关联公司、合作机构、供应商、服务商以及其他合作伙伴。在将信息提供给第三方时，我们会继续遵循合法、正当、必要原则，基于明确的目的，在必要范围内共享您的个人信息，并通过与第三方签订相关协议等方式要求第三方采取管理措施和技术手段保护您的个人信息并严格遵守法律法规及监管要求。我们会按照法律法规及国家标准的要求事先征得您的同意，或确认第三方已经征得您的同意。
          </p>
          <ul>
            <li><p>在获得您的明确同意后，我们会与第三方共享您的个人信息。</p></li>
            <li>我们可能会根据适用法律法规规定，诉讼、仲裁等纠纷解决程序，或按相关监管机关依法提出的要求，向行政机构、司法机构、执法机构或其他监管机构或第三方共享您的个人信息。</li>
            <li>为履行我们在《HoHux 服务条款》和/或本隐私政策项下的义务或权利而可能共享您的个人信息。</li>
            <li>
              我们可能委托受信赖的合作伙伴来提供服务或支持，因此，我们可能会与该等合作伙伴共享您的个人信息，以提供更好的客户服务和优化用户体验；这些服务或支持包括提供基础技术服务、提供咨询、分析等专业服务；该等合作伙伴无权将共享的个人信息用于任何其他用途。
            </li>
            <li>依据与您另行达成的其他协议或信息共享的约定向第三方共享。</li>
            <li><p>应您的合法需求或经您明示同意时向指定第三方传输您的个人信息副本。</p></li>
          </ul>
        </li>
        <li><p><strong>转让</strong></p>
          <p>我们不会主动转让您的个人信息至第三方，但以下情形除外：</p>
          <ul>
            <li><p>转让您的个人信息已经征得您的<strong>明示同意</strong>的；</p></li>
            <li><strong>根据法律法规的规定、有权机关的要求需要转让您的个人信息的</strong>。</li>
            <li><p>在涉及<strong>合并、分立、收购、业务/资产转让、业务/资产重组或破产清算</strong>时，如涉及到个人信息转让，我们会向您
              告知有关情况，并要求新的持有您个人信息的公司、组织继续受本政策的约束。如变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明示同意。
            </p></li>
          </ul>
        </li>
        <li><p><strong>公开披露</strong></p>
          <p>原则上我们不会对外公开披露您的个人信息，如经法律授权或具备合理事由确需公开披露时，我们会向您告知公开披露的目的、披露信息的类型及
            可能涉及的敏感信息，并征得您的明示同意。</p></li>
        <li><p><strong>共享、转让、公开披露个人信息时事先征得授权同意的例外</strong> <strong>根据相关法律法规及国家标准，以下情形中，
          我们共享、转让、公开披露个人信息不必事先征得个人信息主体的授权同意：</strong>
        </p>
          <ul>
            <li><p><strong>与我们履行国家法律法规及监管部门有关规定的义务相关的。</strong></p></li>
            <li><strong>与国家安全、国防安全直接相关的。</strong></li>
            <li><strong>与公共安全、公共卫生、重大公共利益直接相关的。</strong></li>
            <li><strong>与刑事侦查、起诉、审判和判决执行等直接相关的。</strong></li>
            <li><strong>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的。</strong></li>
            <li><strong>您自行向社会公众公开的个人信息，或是我们从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</strong></li>
            <li><strong>法律法规规定的其他情形。</strong></li>
          </ul>
        </li>
      </ol>

      <h2>六、您如何管理您的个人信息</h2>

      <p>
        我们非常重视您对个人信息的关注，并尽全力保护您对个人信息的访问、更正、删除、撤回同意及注销账户的权利。在您使用我们的服务期间，为了您
        可以更加便捷地行使该等权利，我们为您提供了相应的操作设置，您可以参考下面的指引进行操作。</p>

      <ol>
        <li><p><strong>访问和更正您的个人信息</strong></p>
          <ol>
            <li><p>按照适用法律、法规的规定及要求，我们保障您对我们收集的您的信息行使以下权利：</p>
              <ol>
                <li>有权访问我们持有的关于您的个人信息；</li>
                <li>要求我们更正关于您的错误的或不准确的个人信息；</li>
                <li>依照适用法律法规的强制性规定要求我们删除您的个人信息。</li>
              </ol>
            </li>
            <li><p>在您使用我们的服务的过程中，若您需要访问我们所持有的您的个人信息，或在发现我们收集、存储的您的个人信息存在错误的，您可以通过
              <a href="https://home.hohux.com/terms/privacy">https://home.hohux.com/terms/privacy</a>
              途径访问及更正（修改）您的个人信息。</p></li>
          </ol>
        </li>
        <li><p><strong>删除您的个人信息</strong></p>
          <p>您在我们的服务页面可以直接清除或删除的信息，包括社区讨论信息。 符合以下情形中，您可以通过本政策“第九部分 联系我们”所述联系方式联络我们，向我们提出删除个人信息的请求：</p>
          <ol>
            <li><p>我们违反法律法规或与您的约定收集、使用个人信息。</p></li>
            <li>我们违反法律法规或与您的约定与第三方共享、转让您的个人信息，我们将立即停止共享、转让行为，并通知第三方及时删除。</li>
            <li>我们违反法律法规规定或与您的约定，公开披露您的个人信息，我们将立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。</li>
            <li><p>如果您不再使用我们的产品或服务，或您注销了账户，或我们终止了服务及运营。</p></li>
          </ol>
        </li>
        <li><p><strong>改变您的授权同意范围</strong></p>
          <ol>
            <li><p>广告退订：如您不希望收到我们向您发送的广告，您可以根据广告通知和相关提示中提供的方式“退订”或“取消订阅”。</p></li>
            <li><p>营销短信：如您不希望收到我们向您发送的营销短信，您可以根据短信中提供的方式退订。</p>
              <p>
                请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们将无法继续为您提供撤回同意或授权所对应的
                服务，但不影响我们基于您未撤回同意或授权为您提供的其他服务。此外，您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个
                人信息处理。</p>
            </li>
          </ol>
        </li>
        <li><p><strong>注销账户</strong></p>
          <ol>
            <li><p>如果您需要注销 HoHux 服务平台账户，您需要通过本政策“第九部分
              联系我们”所述联系方式联系我们。为了保护您或他人的合法权益，我们需要在您提交注销申请时验证您的身份，需要您在注销页面填写您的姓名
              、您注册账号时使用的手机号码/其他证件号码、您的联系电话以及您的注销原因，用于判断是否支持您的注销请求，但我们不会为您的注销行为
              设置不必要或不合理的条件或障碍。</p>
            </li>
            <li><p>注销后，我们将不会再收集、使用或共享与您账户相关的个人信息，该账户内的个人信息将被删除或匿名化处理，但法律法规或监管机构
              另有规定需要留存的除外。</p></li>
          </ol>
        </li>
        <li><p><strong>响应您的上述请求</strong></p>
          <p>如果您无法通过上述方式对您的个人信息进行访问、更正或删除等操作，或您认为我们存在任何违反法律法规或与您关于个人信息的收集或使用的
            约定，您可以通过本政策中提供的联系方式与我们联系。
            为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，通常情况下，我们将在收到您反馈并验证您的身份后的十五天内或法律
            法规规定期限内受理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。
            对于无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们保留拒绝的权利。
            尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求：</p>
          <ol>
            <li><p>与我们履行法律法规规定的义务相关的。</p></li>
            <li>与国家安全、国防安全有关的。</li>
            <li>与公共安全、公共卫生、重大公共利益直接有关的。</li>
            <li>与刑事侦查、起诉、审判和执行判决等直接有关的。</li>
            <li>有充分证据表明您存在主观恶意或滥用权利的。</li>
            <li>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。</li>
            <li>响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</li>
            <li>涉及商业秘密的。</li>
          </ol>
        </li>
      </ol>

      <h2>七、我们如何保护未成年人的权益</h2>

      <p>您理解并同意，我们仅向实名注册且具有法律规定的完全民事权利能力和民事行为能力、能够独立承担民事责任的成年人提供本服务。如果您是 18
        周岁以下的未成年人，我们将不会对您提供服务（但在未注册情况下进行的有限浏览情形除外）。如果我们发现已收集未成年人的个人信息，我们会采取
        必要措施主动及时地删除相关信息。</p>

      <h2>八、本政策如何更新</h2>

      <ol>
        <li><p>我们的隐私政策可能会适时修订，该等修订于届时公布的生效日期起生效，并取代修订前的版本或构成本隐私政策的一部分。当本政策发生重大
          变化时，我们将及时更新本政策，并采取如下任何一种方式及时通知您：</p>
          <ol>
            <li><p>您登录 HoHux 服务平台或使用我们服务时进行弹窗或公告提醒。</p></li>
            <li>您使用我们的服务时向您推送短消息通知、电子邮件（如您提供）。</li>
            <li><p>在 HoHux 服务平台的官方页面进行公告等。</p>
              <p><strong>请您仔细阅读变更后的本政策内容。如您不同意经变更后的本政策，或对本政策修改、更新的内容有异议，您可以选择停止使用或
                者注销账户。如您继续使用我们的服务，即表示同意受修订后的隐私政策的约束。同时请您知悉，您账户注销之前、停止使用本服务之前的行
                为和活动仍受本政策的约束。</strong>
              </p></li>
          </ol>
        </li>
        <li><p>隐私政策发生重大变化的情形包括但不限于以下情况：</p>
          <ol>
            <li><p>我们提供的服务模式发生重大变化，如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等。</p></li>
            <li>我们在所有权结构、组织架构等方面发生重大变化，如业务调整、破产并购等引起的所有者变更等。</li>
            <li>个人信息共享、转让或公开披露的主要对象发生变化。</li>
            <li>您参与个人信息处理方面的权利及其行使方式发生重大变化。</li>
            <li>我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化。</li>
            <li>个人信息风险评估报告表明存在高风险时。</li>
          </ol>
        </li>
      </ol>

      <h2>九、联系我们</h2>

      <p>我们设立了个人信息保护工作机构，如您对本政策存在任何疑问，或任何相关的投诉、意见，可通过以下一种或多种方式联系我们：</p>

      <ol>
        <li>将您的问题通过 HoHux 官方网站“<a href="https://home.hohux.com/contact">联系我们</a>”与我们联系</li>
      </ol>

      <p>我们将尽快审核您咨询的问题，并在验证您的用户身份后的十五天内或法律法规规定期限内予以回复。</p>

      <h2>十、适用范围</h2>

      <ol>
        <li>本政策适用于 HoHux 服务平台为您提供的功能、产品及/或服务。</li>
        <li>需要特别说明的是，本政策不适用于 HoHux 合作机构或其他第三方向您提供的服务。HoHux
          也可能含有到其他网站的链接，我们会依据法律法规的要求采取必要措施对相关网站进行审查（包括审查网站经营者的基本资质、通过技术手段对相关
          网站的安全情况进行合理且初步识别、督促该等网站经营者根据法律规定保护您的个人信息安全），但我们无法保证该等链接网站的运营方会按照我们
          的要求采取保护措施。如果您在使用本服务的过程中使用到第三方的产品或服务，我们建议您查看该等网站的隐私政策，了解他们如何处理您的个人信
          息，以便审慎决策。
        </li>
      </ol>

      <p><strong>青岛亿云物联科技有限公司</strong></p>

    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "Privacy20210601"
}
</script>

<style scoped>

</style>
