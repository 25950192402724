<template>
  <div>

    <div class="plugin-list-title">
      <span>
          <a-divider type="vertical" style="height: 60px; background-color: #7cb305"/> 热门建议
      </span>

      <router-link to="/contact/wish/create" style="color: #ffffff;margin-left: 0.5em">
        <a-button size="large" type="primary" style="float: right;margin-top: 10px;margin-right: 2em">
          <form-outlined/>
          提建议
        </a-button>
      </router-link>
    </div>

    <a-divider/>

    <a-list item-layout="vertical" size="large" :pagination="pagination" :data-source="listData.list">

      <template #renderItem="{ item }">
        <a-list-item key="item.title">

          <template #extra>

            <a-button size="large" @click="likeWishInfo(item)">
              <like-outlined/>
              {{ item.likeNum }} 票
            </a-button>

          </template>


          <template #actions>

            <span style="margin-left: 2em">
              {{ item.gmtCreate }}
            </span>

            <span>
              {{ item.type }}
            </span>

            <span>
               <eye-outlined style="margin-right: 8px"/>
              {{ item.checkNum }}
            </span>

            <span>
               <star-outlined style="margin-right: 8px"/>
              {{ item.collectNum }}
            </span>
          </template>

          <router-link :to="'/contact/wish/detail/' + item.wishCode">
            <a-list-item-meta :description="item.remark">
              <template #title>
                <a :href="item.href">{{ item.title }}</a>
              </template>
              <template #avatar>
                <EnvironmentOutlined/>
              </template>
            </a-list-item-meta>
          </router-link>

          <!--          {{ item.remarks }}-->

        </a-list-item>
      </template>
    </a-list>

  </div>
</template>

<script>
import {onMounted, reactive, ref} from 'vue';

import {EnvironmentOutlined, EyeOutlined, FormOutlined, LikeOutlined, StarOutlined} from '@ant-design/icons-vue';
import {connectWishList, likeWish} from "@/api/connect";
import {message} from "ant-design-vue";


export default {
  name: "WishList",
  components: {
    StarOutlined,
    LikeOutlined,
    EnvironmentOutlined,
    EyeOutlined,
    FormOutlined
  },
  setup() {
    //列表数据
    const pagination = {
      onChange: page => {
        console.log(page);
      },
      pageSize: 20,
    };
    const actions = [
      {
        type: 'EyeOutlined',
        text: '156',
      },
      {
        type: 'StarOutlined',
        text: '156',
      },
    ];

    // 搜索相关数据
    let value = ref('');

    let searchForm = {};
    let pageData = reactive({
      list: []
    });

    const onSearch = searchValue => {
      console.log('use value', searchValue);
      searchForm.pluginName = searchValue;
      getData();
    };

    const getData = () => {
      connectWishList(searchForm).then((response) => {
        pageData.list = response.data.records;
      })
    }

    const likeWishInfo = (wish) => {
      likeWish(wish.wishCode)
          .then((response) => {
            if (response.data.data) {
              message.success('点赞成功');
              wish.likeNum = wish.likeNum + 1
            } else {
              message.error('点赞失败');
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    onMounted(() => {
      getData();
    })

    return {
      //列表数据
      listData: pageData,
      pagination,
      actions,
      likeWishInfo,

      // 搜索相关数据
      value,
      onSearch,
    };
  },
}
</script>

<style scoped>
.plugin-list-title {
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
  margin-bottom: 16px;
  margin-top: 20px;
}
</style>
