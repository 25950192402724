<template>
  <Index/>
</template>

<script>

import Index from "./views/Index";

export default {
  name: 'App',
  components: {Index}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: "UR";
  src: url("../src/assets/font/Ubuntu-R.ttf");
}
</style>
