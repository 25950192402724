export function autoUrl(searchForm) {
    let url = ""
    if (searchForm) {
        let num = 0;
        for (let key in searchForm) {
            if (searchForm[key] !== undefined) {
                if (num === 0) {
                    url = "?" + key + "=" + searchForm[key];
                    num++;
                } else {
                    url += "&" + key + "=" + searchForm[key];
                }
            }
        }
    }
    return url;
}