<template>
  <a-layout class="layout">

    <Header/>

    <a-layout-content>
      <router-view></router-view>
    </a-layout-content>

    <Footer/>

  </a-layout>
</template>

<script>

import Header from "@/components/ToolBar/Header";
import Footer from "@/components/ToolBar/Footer";

export default {
  name: "Index",
  components: {Footer, Header},
}
</script>

<style scoped>
.ant-layout-content {
  min-height: 86vh;
  /*padding-top: 20px;*/
  padding-bottom: 20px;
  background: #f5f5f5;
}


/*[data-theme='dark'] .site-layout-content {*/
/*  background: #141414;*/
/*}*/
</style>
