<template>
  <div>

    <Banner/>

    <!--  特性简介  -->
    <Characteristic/>

    <!--    快速开始    -->
    <QuickStart/>

    <!--   更多探索 -->
    <Explore/>

    <!--  应用场景  -->
    <Solution/>

    <div id="components-back-top-demo-custom">
      <router-link to="/contact">
        <a-back-top @click="connect">
          <div class="ant-back-top-inner">
            <whats-app-outlined/>
            联系我们
          </div>
        </a-back-top>
      </router-link>
    </div>

  </div>

</template>

<script>
import Solution from "@/components/home/Solution";
import Characteristic from "@/components/home/Characteristic";
import QuickStart from "@/components/home/QuickStart";
import Explore from "@/components/home/Explore";
import Banner from "@/components/home/Banner";
import {WhatsAppOutlined} from '@ant-design/icons-vue';

export default {
  name: "Home",
  components: {
    Banner,
    Explore,
    QuickStart,
    Characteristic,
    Solution,
    WhatsAppOutlined
  },
  data() {
    return {
      msg: 'Welcome to Use Tinymce Editor',
    }
  },
  methods: {
    connect() {
      console.log('联系我们')
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
#components-back-top-demo-custom .ant-back-top {
  bottom: 100px;
  margin-right: 50px;
}

#components-back-top-demo-custom .ant-back-top-inner {
  height: 40px;
  width: 150px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

</style>
