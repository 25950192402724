<template>

  <div>

    <div>
      <img src="../../assets/img/connect/联系我们-2.png">
    </div>

    <a-row style="margin-top: 28px">
      <a-col :span="12" :offset="6">

        <div class="connect-form">

          <h2>
            联系我们
          </h2>

          <a-form
              :model="formState"
              name="basic"
              layout="vertical"
              autocomplete="off"
              @finish="onFinish"
              @finishFailed="onFinishFailed"
          >
            <a-form-item
                label="姓名"
                name="name"
                :rules="[{ required: true, message: '请输入姓名' }]"
            >
              <a-input v-model:value="formState.name" size="large"/>
            </a-form-item>

            <a-form-item
                label="公司"
                name="company"
                :rules="[{ required: true, message: '请输入公司名称' }]"
            >
              <a-input v-model:value="formState.company" size="large"/>
            </a-form-item>

            <a-form-item
                label="邮箱"
                name="mailbox"
                :rules="[{ required: true, message: '请输入邮箱' }]"
            >
              <a-input v-model:value="formState.mailbox" size="large"/>
            </a-form-item>

            <a-form-item
                label="手机号码"
                name="phone"
                :rules="[{ required: true, message: '请输入手机号码' }]"
            >
              <a-input v-model:value="formState.phone" size="large"/>
            </a-form-item>

            <a-form-item
                label="应用场景"
                name="scenes"
                :rules="[{ required: true, message: '请输入应用场景' }]"
            >
              <a-input v-model:value="formState.scenes" size="large"/>
            </a-form-item>

            <a-form-item
                label="详细信息"
                name="remark"
                :rules="[{ required: true, message: '请输入详细信息，以便我们更好的帮助您' }]"
            >
              <a-textarea v-model:value="formState.remark" :rows="4"/>
            </a-form-item>

            <a-form-item>
              <a-button type="primary" html-type="submit" size="large">立即提交</a-button>
            </a-form-item>
          </a-form>

        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {message} from 'ant-design-vue';
import {reactive} from 'vue';
import {saveConnect} from "@/api/connect";

export default {
  name: "Index",
  setup() {
    const formState = reactive({
      name: '',
      company: '',
      mailbox: '',
      phone: '',
      scenes: '',
      remark: '',
    });

    const onFinish = values => {
      saveConnect(values)
          .then((response) => {
            if (response.data) {
              message.success('提交成功');
              location.reload()
            } else {
              message.error('提交失败，请重试');
            }
          })
          .catch((error) => {
            console.log(error);
          });
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      onFinish,
      onFinishFailed,
    };
  },
}
</script>

<style scoped>
img {
  width: 100%;
  /*height: 10%;*/
}

.connect-form {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
}


.connect-form h2 {
  text-align: center;
}
</style>
