<template>
  <div>
    <div>
      <img src="../../../assets/img/connect/聆听建议2.png">
    </div>

    <a-row>
      <a-col :span="18" :offset="3">
        <div class="connect-row">
          <WishList/>
        </div>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import WishList from "@/components/connect/WishList";

export default {
  name: "Wish",
  components: {WishList}
}
</script>

<style scoped>
img {
  width: 100%;
}

.connect-row {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
}
</style>
