import cookies from "js-cookie";
import {ACCESS_TOKEN} from "@/store/mutation-types";

export function authLogout() {
    localStorage.clear();
    cookies.remove(ACCESS_TOKEN);
    cookies.remove(ACCESS_TOKEN, {
        domain: '.hohux.com'
    });
    // location.href = "https://www.hohux.com";
}
