<template>
  <a-row class="characteristic">
    <a-col :span="16" :offset="4">
      <!--  特性简介  -->
      <a-row>
        <a-col :span="8" v-for="item in feature" :key="item.key">
          <div class="feature">
            <h2>{{ item.title }}</h2>
            <p>
              {{ item.description }}
            </p>
          </div>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "Characteristic",
  data() {
    return {
      feature: [
        {
          key: 1,
          title: "简单",
          description: "只需简单配置，即可快速连接设备，进行设备控制、监控，从而节省大量时间。"
        }, {
          key: 2,
          title: "高效",
          description: "更高的吞吐量，更低的延迟，减少资源消耗，支持海量设备多协议互联互通。"
        }, {
          key: 3,
          title: "安全",
          description: "安全网关防护，完整的 SSL/TLS 和 StartTLS 支持，多重安全防护，保护设备安全。"
        },
      ]
    }
  }
}
</script>

<style scoped>

.characteristic {
  background-color: #ffffff;
}

.feature {
  padding-right: 4em;
  height: 300px;
  padding-top: 80px;
}

.feature h2 {
  text-align: center;
  font-size: 1.65rem;
  font-weight: 500;
  border-bottom: none;
  padding-bottom: 0;
  color: #1890ff;
}

.feature p {
  /*color: #4e6e8e;*/
  /*line-height: 1.7;*/
  /*font-size: 16px;*/
  margin: 15px 0 0 0;
  font-size: 18px;
  color: #697A89;
  letter-spacing: 0;
  line-height: 28px;
  max-width: 532px;
}
</style>
