<template>
  <div>
    <a-row>
      <a-col :span="18" :offset="3" style="margin-top: 30px">
        <h1>HoHux 服务条款</h1>

        <p>版本生效日期：2021 年 8 月 13 日</p>

        <p>欢迎您（以下简称“<strong>用户</strong>”或“<strong>您</strong>”）访问由青岛亿云物联科技有限公司（以下简称 “<strong>HoHux</strong>”
          或“<strong>我们</strong>”）运营管理的 HoHux 服务平台（包括但不限于网址为 www.hohux.com
          的网站、微信公众号、小程序、APP、云服务平台等，以下统称“本平台”）以使用我们提供的产品及服务。</p>

        <p><strong>您在首次使用本平台服务前，应当认真阅读并选择是否接受本《HoHux
          服务条款》（以下简称“本协议”）。本协议将努力采取清晰易懂、简明通俗的表述，并以显着方式（如加粗、加下划线等）提示您注意并理解本协议中与您有重大利害关系的相关条款。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制本平台责任的条款和争议解决条款。如您不同意本协议的任何内容，请不要进行后续操作，如您无法准确理解相关条款您可向本平台客服咨询。您也可以通过
          HoHux 官方网站</strong></p>

        <p><strong>您一经点击确认或选择使用本平台服务或以其他方式选择接受本协议即表示您已充分阅读、理解并接受本协议的全部内容，并与本平台达成一致，成为本平台用户。本协议对您及本平台均具有法律约束力。本平台有权在必要时自主决定修订本协议，修订后的协议以届时平台公布的生效时间起生效，并将以本平台公告、弹窗、站内信、公众号、电子邮件、短信等一种或多种方式在生效时间以前通知您。您可以随时在本平台查阅本协议的最新版本。如您不同意修订后的本协议，您有权停止使用本平台服务；本协议更新或修订后，如果您继续使用本平台服务，即视为您已同意修改后的协议并受其约束。</strong>
        </p>

        <h2>一、服务内容</h2>

        <p>本服务指 HoHux 服务平台向用户提供的产品及服务（以下统称为“本服务”），具体产品及服务内容以届时 HoHux
          服务平台购买具体产品及服务时的展示页面及适用的相关服务规则（包括但不限于具体协议、说明、介绍等）为准。</p>

        <h2>二、适用范围</h2>

        <ol>
          <li><strong>您确认，在您完成注册程序或以其他 HoHux
            允许的方式实际使用本服务时，您应当是中华人民共和国法律规定的完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。如果您不具备前述主体资格，您及您的法定监护人应承担由此导致的一切后果，且我们有权注销您的账户并保留追究您相应责任的权利。</strong>
          </li>
          <li><strong>您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用本服务。</strong></li>
          <li>
            您应按照本平台的注册/登录页面提示阅读并同意本协议且完成创建账户程序后即可创建本平台账户。在注册本平台账户及使用本服务的过程中，您应当按照我们的要求提供合法、真实、准确及完整的身份信息并及时更新，以便我们为您提供本服务并按照法律法规和监管要求履行相应义务。
          </li>
          <li>
            如我们发现或有合理理由怀疑您提供的身份信息不合法、不真实、不准确、不完整，我们有权立即中止（或终止）向您提供本服务，并拒绝您现在或未来使用本服务全部或部分功能，由此给您造成的任何损失由您自行承担；如您未及时更新您的身份信息，而导致我们无法及时为您提供本服务或本服务的有关流程及操作发生任何错误，您需承担由此引起的相应责任和后果，并且我们保留中止（或终止）您使用本平台各项服务的权利。
          </li>
        </ol>

        <h2>三、特别提示</h2>

        <ol>
          <li><strong>HoHux 可能会以本平台公告、弹窗、电子邮件、站内信、公众号短信或电话等方式通知您服务进展情况以及提示您进行下一步的操作。在服务过程中您应当及时登录到 HoHux
            账户查看和进行操作。</strong></li>
          <li><strong>通常情况下，您的账户是您在网站进行一切活动的唯一身份标识，除非另有约定，每一个账户都可以在本平台独立开展活动。但在下列情形下，HoHux
            有权根据自己的判断，对同一及/或关联法律主体拥有的多个账户进行统一处理，如根据不同账户在注册、登录、使用中的关联信息，HoHux
            判断其实际为同一用户。关联信息举例：同一证件、同一手机号、同一支付账号、同一设备、同一地址等。</strong></li>
          <li><strong>对于您的账户，除非有法律明文规定、司法裁判或者经 HoHux 同意的情况下，您不得以任何方式转让、赠与或让他人继承您的账户。</strong></li>
          <li><strong>您的账户和密码由您自行设置并由您保管，您须对您的账户和密码保密。HoHux 通过您的用户名和密码识别您的指示，您通过您的用户名和密码进行的所有操作均视为您自身的操作、指示，不可撤回或撤销，您应对
            HoHux 基于提供本服务而执行该等操作、指示所产生的后果承担责任。您需确保您在每个上网时段结束时，以正确步骤离开网站。账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，HoHux
            并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</strong></li>
          <li><strong>您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买产品及服务及披露信息等）负责。</strong></li>
          <li><strong>为了向您提供本服务，您同意并授权我们按照本协议及《<a href="https://home.hohux.com/terms/privacy">HoHux 隐私政策</a>》的相关规定收集、处理、保护您的个人信息。</strong>
          </li>
          <li>您在本平台注册所使用的姓名、头像等需符合法律法规的规定、公序良俗及社会公德，且不会侵害其他第三方的合法权益，否则我们有权对您的 HoHux
            账户采取限制措施，包括但不限于屏蔽、撤销您账户的姓名和/或头像、停止提供部分或全部本服务。
          </li>
          <li>为了更好地为您提供本服务，便于您及时了解使用本服务的情况，您同意我们可以在相关法律法规及监管要求允许的情况下，通过本平台的相关页面和信息渠道向您展示本服务信息。</li>
        </ol>

        <h2>四、服务功能</h2>

        <p>您可以通过本平台使用本平台提供的各项服务。为免疑义，我们保留适时变更本服务功能的权利，<strong>具体服务内容及事项以您申请开通或使用时，本平台实际向您开放或者提供的为准。请注意，您通过本平台点击、浏览、授权开通或实际使用相关的功能和服务，即视为您同意开通相关功能。</strong>
        </p>

        <h2>五、用户权利义务</h2>

        <ol>
          <li>您确认并同意在使用本协议第四条约定的服务功能过程中，应遵守法律法规，根据法律法规和协议约定享有权利并承担义务。</li>
          <li>您使用本服务，您应保证：
            <ol>
              <li>遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用 HoHux 提供的本服务进行存储、发布、传播如下信息和内容：
                <ol>
                  <li>违反国家法律法规政策的任何内容（信息）；</li>
                  <li>违反国家规定的政治宣传和/或新闻信息；</li>
                  <li>涉及国家秘密和/或安全的信息；</li>
                  <li>封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；</li>
                  <li>博彩有奖、赌博游戏；</li>
                  <li>违反国家民族和宗教政策的信息；</li>
                  <li>妨碍互联网运行安全的信息；</li>
                  <li>侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容。</li>
                </ol>
              </li>
              <li>您同时承诺不得为他人发布上述不符合国家规定和/或本协议约定的信息内容提供任何便利，包括但不限于设置 URL、BANNER 链接等；</li>
              <li>不应出现任何破坏或试图破坏网络安全等的行为，包括不会利用技术或其他手段破坏或扰乱本平台；</li>
              <li>您使用本服务应符合本协议；</li>
              <li>如您违反上述保证，HoHux 除有权根据相关服务条款采取删除信息、中止服务、终止服务的措施，并有权冻结或注销您账户部分或全部功能；</li>
              <li>本平台涉及的部分业务需您与 HoHux 另行签订相关协议，同时您应严格按照所签协议履行全部义务，否则您将无法继续享受 HoHux 提供的服务、产品。</li>
            </ol>
          </li>
          <li>您保证，您在使用本服务时不得从事包括但不限于以下行为，也不得为该等行为提供便利：
            <ol>
              <li>任何滥用 HoHux 资源以及无意义创建、删除 HoHux 资源的行为，包括但不限于任何与黑客攻击相关的行为、虚拟货币挖矿、网格计算、抢购等；</li>
              <li>任何改变或试图改变本服务提供的系统配置或系统安全的行为；</li>
              <li>任何干扰或试图干扰 HoHux 任何产品、服务或任何功能的正常运行；</li>
              <li>利用技术或其他手段破坏及扰乱 HoHux 及 HoHux 其他用户的网站和服务的行为；</li>
              <li>实施违法或未经授权的接取、盗用、干扰或监测的；</li>
              <li>因您从事的业务而导致您遭受频繁或大规模 DDOS 攻击的。您承认，HoHux 有权在您出现上述行为的情况下选择暂停账户访问、中止服务或立即终止向您提供本服务并不退还任何款项；因您上述行为给 HoHux
                及第三方造成损失的，您应予赔偿；
              </li>
              <li>您知悉并同意，若 HoHux 自行发现、根据相关监管机关的要求或来自相关权利人投诉，发现您有违反相关法律法规或本协议（包括 HoHux 相关协议、规则）项下有关您义务的约定的，HoHux
                有权在必要时候视具体情况及相关要求采取以下一种或多种处理措施：
                <ol>
                  <li>要求您立即删除、修改相关内容；</li>
                  <li>直接删除、屏蔽相关内容；</li>
                  <li>中止您使用的部分或全部服务；</li>
                  <li>终止您使用的服务。因此导致的后果及责任由您自行承担，给 HoHux 造成损失的（包括但不限于因此导致任何行政主管机关对 HoHux 的行政处罚等），您应该予以赔偿。</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>

        <h2>六、不可抗力及其他免责事由</h2>

        <ol>
          <li>
            您理解并同意，在使用本服务的过程中，可能会遇到不可抗力（即不能预见、不能克服、不能避免且对一方或双方造成重大影响的客观事件）、技术风险等因素使本服务发生中断，出现下述情况时，我们将努力在第一时间与相关单位配合及时进行修复，但由此给您造成的损失我们在法律允许的范围内免责：
            <ol>
              <li><strong>因洪水、地震、风暴等自然灾害、流行病、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</strong></li>
              <li><strong>您、本平台的电脑软件、系统、硬件和通信线路出现故障或其他原因导致您无法使用本平台；</strong></li>
              <li><strong>因电力供应故障、通讯网络故障（包括但不限于电子通讯传达失败或延时、用于电子通讯的计算机程序对电子通讯的拦截或操纵）等公共服务因素或您自身因素（包括但不限于您操作不当、错误下单、通过非本平台授权的方式使用本服务）或第三人因素（包括但不限于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏等错误操作）；</strong>
              </li>
              <li><strong>在本平台已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、缺陷、网络与数据安全、技术风险等因素；</strong></li>
              <li><strong>其他非本平台过错或者本平台无法控制或无法合理预见的情形。</strong></li>
            </ol>
          </li>
          <li>您在使用本服务的过程中，我们不对下列风险担责：
            <ol>
              <li>来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；</li>
              <li>遭受他人误导、欺骗或其他导致任何心理、生理上的伤害以及经济上的损失。</li>
            </ol>
          </li>
          <li>您在使用本服务过程中，我们有权在法律规定的范围内处理违法违规内容，但其不构成我们的义务或承诺。</li>
        </ol>

        <h2>七、暂停、中断或终止服务</h2>

        <ol>
          <li><strong>我们有权基于业务调整或风险管控的需要，暂停、中断或终止向您提供本服务。</strong></li>
          <li>我们在发现您有可疑交易或涉嫌违法违规时，有权不经通知暂停、中断或终止向您提供本服务。</li>
          <li>如我们发现您违反本协议约定的，有权暂停、中断或终止向您提供本服务。</li>
        </ol>

        <h2>八、责任范围及责任限制</h2>

        <ol>
          <li><strong>HoHux 仅在本协议中列明的责任承担范围内承担相应责任。</strong></li>
          <li><strong>HoHux 用户信息是由您本人自行提供的，HoHux 无法保证该信息之准确、及时和完整。</strong></li>
          <li><strong>对本协议第七条所列情形而导致的系统中断或故障而致本服务无法使用，不构成 HoHux 违约，且因此导致的损失 HoHux 不予赔偿。</strong></li>
          <li><strong>您在使用本服务期间应当遵守中华人民共和国的法律，不得危害网络安全，不得利用本服务从事侵犯他人名誉、隐私、知识产权和其他合法权益的活动。HoHux
            不对您使用本服务的违法或违约行为承担任何责任。</strong></li>
          <li><strong>HoHux
            仅就本服务本身提供运营维护，您应当保证自身设备、网络等安全、稳定、具备适于开通本服务的必要条件，若因您不能提供上述必要条件而导致您所购买的本服务不能提供、迟延提供或造成本服务缺陷的，HoHux
            不承担因此导致的责任或损失。此种情况下，HoHux 提供本服务的义务相应延迟，在合理期限内您不能消除自身所造成的障碍的，则 HoHux 有权终止本协议，因此造成 HoHux 损失的，您应予以赔偿。</strong>
          </li>
          <li><strong>您同意，在 HoHux 无重大过失或过错的情况下，无需对您使用本服务时数据传输的延迟、错误或疏漏及因此导致的损失承担责任。</strong></li>
          <li><strong>HoHux 对通过您间接接受本服务的第三方（包括但不限于您的最终用户）的损失不承担责任。</strong></li>
          <li><strong>第三方服务（包括但不限于基于本平台提供的第三方应用）由其他自然人、法人或其他组织直接向您提供，对于您使用第三方服务而导致的责任、风险，由您自行承担。</strong></li>
          <li><strong>在法律允许的情况下，HoHux
            对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任。</strong>
          </li>
          <li><strong>您理解并同意，本服务是按照现有技术和条件所能达到的现状而提供的。HoHux 并不能保证所提供的本服务毫无瑕疵；所以，即使 HoHux
            提供的本服务存在瑕疵，但若上述瑕疵是其时行业技术水平所无法避免的，其将不视为 HoHux 违约，您同意与我们一同就上述问题共同协商寻求解决方式。</strong></li>
        </ol>

        <h2>九、隐私保护及保密</h2>

        <p>我们非常重视对您隐私的保护，您的个人隐私信息将根据《HoHux 隐私政策》受到保护与规范，详情请参阅《<a href="https://home.hohux.com/terms/privacy">HoHux 隐私政策</a>》。
        </p>

        <h2>十、知识产权保护</h2>

        <ol>
          <li>基于您使用本服务及在使用本服务期间，HoHux 提供给您的产品、服务涉及的软件及相关技术资料的知识产权归 HoHux 所有及我们的关联公司或者合作伙伴所有。</li>
          <li>除非本协议另有约定，上述软件及技术资料的知识产权及所有权仍归 HoHux 及我们的关联公司或者合作伙伴所有，您不得删除、修改或掩藏任何 HoHux
            或第三方所提供的相关软件中包含的任何版权、商标或其他专有权声明。
          </li>
          <li>您保证在使用本服务过程中没有侵犯任何第三方知识产权的行为；若您违反上述约定，HoHux 有权按照本条及本协议之其他相关约定终止您全部或部分服务；由此导致 HoHux 或第三方损失的，由您自行承担责任。</li>
          <li>
            双方明确，任何一方的商号、商标、品牌及其他标识均为该方的专有财产，本协议中任何规定均不构成一方向另一方授予上述商号、商标、品牌及其他标识的使用许可。除本协议另有明确约定，若非经相关方书面同意，任何一方均不得显示、使用或允许任何第三方显示、使用另一方的任何商号、商标、品牌及其他标识。
          </li>
          <li>即便上述有关知识产权的约定已包含相关权利约定，但 HoHux 仍特别声明，本平台内容（除第三方提供的产品、服务外），包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由 HoHux
            依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等；非经 HoHux 书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本平台内容。
          </li>
          <li>本条款在本协议终止后对本协议各方仍继续有效。</li>
        </ol>

        <h2>十一、违约责任</h2>

        <ol>
          <li>发生如下情形之一的，视为您违约：
            <ol>
              <li><strong>使用本服务时违反有关法律法规规定的；</strong></li>
              <li><strong>违反本协议或本协议之补充协议（如有）约定的。</strong></li>
            </ol>
          </li>
          <li><strong>如您的行为使 HoHux 或关联公司遭受损失（包括但不限于自身的直接经济损失、商誉损失及对第三人支付的赔偿金、和解款、律师费、诉讼费等间接损失），您应赔偿 HoHux
            或关联公司上述全部损失。</strong></li>
        </ol>

        <h2>十二、法律适用及争端解决</h2>

        <ol>
          <li>除非本协议另有约定或双方另行达成书面协议，本协议在您使用本服务期间均持续有效。</li>
          <li><strong>本协议签订地为中华人民共和国浙江省杭州市余杭区。</strong></li>
          <li>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</li>
          <li><strong>您和我们因本协议产生任何纠纷或争议的，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交至本协议签订地有管辖权的人民法院解决。</strong></li>
        </ol>

        <h2>十三、其他条款</h2>

        <ol>
          <li>本协议所有条款的标题仅为阅读方便，不应以任何方式影响本协议的涵义。</li>
          <li>未经我们书面同意，您不得将本协议项下的权利和义务转让给任何第三方。但为确保向您提供持续服务，经书面通知或在线公告等适当形式通知后，我们可以将本协议项下的权利和义务全部或部分转让给第三方。</li>
          <li>您使用本服务时，我们可使用电子方式向您发出通知，上述电子方式包括但不限于官方微信公众号、电子邮箱、手机短信、电话、APP 及网站公告或站内信等方式。</li>
          <li>本协议条款无论因何种原因部分无效，其余条款仍有效且对双方具有约束力。</li>
          <li><strong>《HoHux 隐私政策》与本协议共同组成我们与您之间就 HoHux 服务的完整约定。《HoHux
            隐私政策》将被视为完整纳入本协议，成为本协议的一部分。在法律法规允许的范围内，我们将根据法律法规、监管要求与业务需求适时修改《HoHux 隐私政策》及本协议，请您及时关注和了解。若您不同意修改后的《HoHux
            隐私政策》及本协议，您有权停止使用本服务；若您继续使用本服务，视为您同意修改后的《HoHux 隐私政策》及本协议。</strong></li>
        </ol>

        <p><strong>青岛亿云物联科技有限公司</strong></p>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "Service20210601"
}
</script>

<style scoped>

</style>
