import axios from "axios";
import { autoUrl } from "@/utils/autoUrl";

const pluginsApi = {
    getPlugins: '/iotcore-service/plugins',
    getPluginsInfo: '/iotcore-service/plugins/info/',
    getPluginsAndCode: '/iotcore-service/plugins/code/',
    getPluginsHub: '/iotcore-service/plugins/hub',
    savePlugins: '/iotcore-service/plugins',
    deletePlugins: '/iotcore-service/plugins/',
    code: '/iotcore-service/plugins/code',
    hubHome: '/iotcore-service/plugins/hub/home',
}

export function getPlugins(searchForm) {
    return axios({
        method: 'get',
        url: pluginsApi.getPlugins + autoUrl(searchForm)
    });
}

export function getPluginsHub(searchForm) {
    return axios({
        method: 'get',
        url: pluginsApi.getPluginsHub + autoUrl(searchForm)
    });
}

// 查询插件数据和代码信息
export function getPluginsInfoAndCode(pluginCode) {
    return axios({
        method: 'get',
        url: pluginsApi.getPluginsAndCode + pluginCode
    });
}

// 查询插件数据
export function getPluginsInfo(pluginCode) {
    return axios({
        method: 'get',
        url: pluginsApi.getPluginsInfo + pluginCode
    });
}

export function getHubHome() {
    return axios({
        method: 'get',
        url: pluginsApi.hubHome
    });
}

export function savePlugins(parameter) {
    return axios({
        method: 'post',
        url: pluginsApi.savePlugins,
        data: parameter
    });
}

export function saveCode(parameter) {
    return axios({
        method: 'post',
        url: pluginsApi.code,
        data: parameter
    });
}

export function updatePlugins(parameter) {
    return axios({
        method: 'put',
        url: pluginsApi.savePlugins,
        data: parameter
    });
}

export function deletePlugins(id) {
    return axios({
        method: 'delete',
        url: pluginsApi.deletePlugins + id,
    });
}
