<template>
  <div>
    <a-row>
      <a-col :span="18" :offset="3" style="margin: 0 auto">

        <a-breadcrumb style="margin-top: 20px;margin-bottom: 20px">
          <a-breadcrumb-item>
            <router-link to="/plugins">插件市场</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>{{ pageData.info.pluginName }}</a-breadcrumb-item>
        </a-breadcrumb>


        <div style="padding: 20px">
          <a-row :gutter="16">
            <a-col :span="5">

              <a-image
                  :src="pageData.info.icon"
              />
            </a-col>
            <a-col :span="18">
              <a-card :title="pageData.info.pluginName" :bordered="false">
                <p> 开发者：{{ pageData.info.deptName }} </p>
                <p> 更新时间：{{ pageData.info.updateTime }} </p>
<!--                <p>****** (910) | 100,000+ 个用户 | 高效工作</p>-->
              </a-card>
            </a-col>

          </a-row>
        </div>

<!--        <a-divider/>-->

        <a-descriptions title="详细信息">
          <a-descriptions-item label="开发者"> {{ pageData.info.deptName }}</a-descriptions-item>
          <a-descriptions-item label="版本号"> {{ pageData.info.versions }}</a-descriptions-item>
          <br/>
          <a-descriptions-item label="发布时间"> {{ pageData.info.creationTime }}</a-descriptions-item>
          <a-descriptions-item label="更新时间"> {{ pageData.info.updateTime }}</a-descriptions-item>
        </a-descriptions>

        <a-divider/>

        <a-descriptions title="描述">
          <a-descriptions-item label="">
            {{ pageData.info.remarks }}
          </a-descriptions-item>
        </a-descriptions>

      </a-col>
    </a-row>
  </div>
</template>

<script>
import {onMounted, reactive} from "vue";
import {getPluginsInfo} from "@/api/plugins";
import {useRouter} from "vue-router";

export default {
  name: "pluginsDetail",
  setup() {

    const router = useRouter()
    const pluginCode = router.currentRoute.value.params.id;

    let pageData = reactive({
      info: {},
    });

    const getData = () => {
      getPluginsInfo(pluginCode)
          .then((response) => {
            pageData.info = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    }

    onMounted(() => {
      getData();
    })

    return {
      pageData
    }
  }
}
</script>

<style scoped>

</style>
