<template>
  <a-row style="background: white;padding-bottom: 5em">
    <a-col :span="16" :offset="4">

      <div class="gutter-example" style="margin-top: 50px;">

        <div style="text-align: center;margin-top: 50px">
          <h2>更多探索，等你发现</h2>
        </div>

        <a-row :gutter="16" class="explore-row">
          <a-col class="gutter-row" :span="12">
            <div class="gutter-box">
              <h3>设备管理</h3>
              <p>
                设备是HoHux系统组成的重要部分，设备管理对所有的设备进行统一管理，提供设备基本信息、连接信息。
                可以对设备进行远程控制，检测设备运行状态以及对传感器数据监控等。
                同时还提供设备及传感器历史数据存储、分析功能，使用户能够快速方便的管理、监控、分析设备信息及其数据。
              </p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="12">
            <div class="gutter-box" style="text-align: center">
              <img width="180" height="180" src="@/assets/img/home/设备管理.svg"/>
            </div>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col class="gutter-row" :span="12">
            <div class="gutter-box" style="text-align: center">
              <img width="180" height="180" src="@/assets/img/home/数据互通.svg"/>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="12">
            <div class="gutter-box">
              <h3>插件市场</h3>
              <p>
                你可以在插件市场中找到任何你所需要的插件，各种插件可以将你的设备在不同协议之间任意转换。
                你也可以将自己开发的插件发布的插件市场中，供其他用户使用你的服务，将更多的设备互联互通。
                插件市场绝不仅仅是一个在线市场，它更是创新的大本营，致力于为你带来精彩的体验。
              </p>
            </div>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col class="gutter-row" :span="12">
            <div class="gutter-box">
              <h3>场景联动</h3>
              <p>
                场景联动通过整合设备管理信息、数据分析服务和应用服务，结合实际应用场景，为你提供一站式场景应用开发，提高物联网应用体验。
                通过不同设备不同传感器数据，进行场景联动可以更便捷、高效的发挥物联网应用的优点，以此实现复杂的应用场景，优化解决现实环境中的问题。
              </p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="12">
            <div class="gutter-box" style="text-align: center">
              <img width="180" height="180" src="@/assets/img/home/场景管理.svg"/>
            </div>
          </a-col>
        </a-row>


        <a-row :gutter="16">
          <a-col class="gutter-row" :span="12">
            <div class="gutter-box" style="text-align: center">
              <img width="180" height="180" src="@/assets/img/home/安全预警.svg"/>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="12">
            <div class="gutter-box">
              <h3>安全预警</h3>
              <p>
                通过数据分析服务与场景联动服务，实现设备及其传感器安全预警，通过自定义设置，可以有效的监控设备与传感器的安全运行状态，从而达到设备全生命周期安全检测。
                通过安全预警服务与场景联动服务相结合，快速实现设备自动化控制与监控，消除可能存在的安全隐患，减少损失。
              </p>
            </div>
          </a-col>
        </a-row>
      </div>

      <div class="learn-more">
        <a type="primary" href="https://www.hohux.com/plugins">
          插件市场
        </a>
      </div>

    </a-col>
  </a-row>
</template>

<script>

export default {
  name: "Explore",
  components: {},
}
</script>

<style scoped>

.gutter-example :deep(.ant-row > div) {
  background: transparent;
  border: 0;
}

.gutter-box {
  padding: 5px 0 50px;
}

h2 {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 48px;
  color: #262626;
  line-height: 56px;
  font-weight: 700;
}

h3 {
  font-weight: 600;
  font-size: 22px;
  color: #262626;
  line-height: 26px;
}

p {
  margin: 15px 0 0 0;
  font-size: 18px;
  color: #697A89;
  letter-spacing: 0;
  line-height: 28px;
  max-width: 532px;
}


.learn-more {
  margin: 1.8rem auto;
  height: 60px;
  width: 150px;
  text-align: center;
  line-height: 60px;
  background: #0073ec;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  border-width: 2px;
  cursor: pointer
}

.learn-more a {
  font-size: 18px;
  color: #FFFFFF;
}
</style>
