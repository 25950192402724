<template>
  <a-row class="home-quick-start">
    <a-col :span="16" :offset="4">

      <!--    快速开始    -->
      <div class="home-quick-start-steps">
        <h2>简单三步，快速开始</h2>

        <a-steps :current="current">
          <a-step v-for="item in steps" :key="item.title" :title="item.title" @click="next(item.current)"
                  style="cursor:pointer"/>
        </a-steps>
        <div class="steps-content">
          <p>{{ steps[current].content }}</p>
        </div>
      </div>

      <div class="home-quick-start-card">
        <h2>多种协议，快速连接</h2>
        <a-card>

          <a-card-grid class="home-quick-start-card-grid">
            <table>
              <tr>
                <td class="home-quick-start-card-grid-td">
                  <swap-outlined class="home-quick-start-card-grid-icon"/>
                </td>
                <td>
                  <h3 class="home-quick-start-card-grid-h3">MQTT</h3>
                  <p>
                    以极少的代码和有限的带宽，为设备提供实时可靠的消息服务。
                  </p>
                </td>
              </tr>
            </table>
          </a-card-grid>

          <a-card-grid class="home-quick-start-card-grid">
            <table>
              <tr>
                <td class="home-quick-start-card-grid-td">
                  <wifi-outlined class="home-quick-start-card-grid-icon"/>
                </td>
                <td>
                  <h3 class="home-quick-start-card-grid-h3">TCP/IP</h3>
                  <p>
                    面向连接的、可靠的、基于字节流的传输层通信协议。
                  </p>
                </td>
              </tr>
            </table>
          </a-card-grid>

          <a-card-grid class="home-quick-start-card-grid">
            <table>
              <tr>
                <td class="home-quick-start-card-grid-td">
                  <api-outlined class="home-quick-start-card-grid-icon"/>
                </td>
                <td>
                  <h3 class="home-quick-start-card-grid-h3">HTTP</h3>
                  <p>
                    用于分布式、协作式和超媒体信息系统的应用层协议。
                  </p>
                </td>
              </tr>
            </table>
          </a-card-grid>
        </a-card>
      </div>

      <div class="learn-more">
        <a type="primary" href="https://docs.hohux.com/">
          了解更多
        </a>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import {WifiOutlined, SwapOutlined, ApiOutlined, RetweetOutlined} from '@ant-design/icons-vue';

export default {
  name: "QuickStart",
  components: {
    WifiOutlined,
    SwapOutlined,
    ApiOutlined,
    // eslint-disable-next-line vue/no-unused-components
    RetweetOutlined
  },
  data() {
    return {
      current: 0,
      steps: [{
        current: 0,
        title: '创建产品',
        content: '产品是设备的集合，通常是一组具有相同功能定义的设备集合。例如，产品指同一个型号的产品，设备就是该型号下的某个设备。',
      }, {
        current: 1,
        title: '创建传感器',
        content: '传感器是组成设备的基本元素，不仅仅局限于物理传感器，可以是设备的任何信息。传感器主要用于采集数据信息。',
      }, {
        current: 2,
        title: '创建设备',
        content: '设备是HoHux系统组成的重要部分，设备由一个或多个传感器组成，设备管理对所有的设备进行统一管理，提供设备基本信息、连接信息。可以对设备进行实时监控，控制。',
      }],
    }
  },
  methods: {
    next(current) {
      this.current = current;
    }
  }
}
</script>

<style scoped>

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 130px;
  text-align: center;
  padding-top: 40px;
}

.steps-action {
  margin-top: 24px;
}

[data-theme='dark'] .steps-content {
  background-color: #2f2f2f;
  border: 1px dashed #404040;
}


.home-quick-start {
  background-color: #ececec;
  padding-bottom: 50px;
  padding-top: 50px
}

.home-quick-start .home-quick-start-steps {

}

.home-quick-start .home-quick-start-steps h3 {
  font-weight: 400;
  font-size: 22px;
  color: #262626;
  line-height: 26px;
}

.home-quick-start-steps p {
  margin: 0 50px;
  text-align: left;
}

h2 {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 48px;
  color: #262626;
  line-height: 56px;
  font-weight: 700;
}

.home-quick-start .home-quick-start-card {
  margin-top: 50px;
  margin-bottom: 50px;
}

.home-quick-start .home-quick-start-card .home-quick-start-card-grid {
  width: 33.333333%;
}

.home-quick-start-card-grid-td {
  width: 30%;
  text-align: center;
  padding-right: 12px;
}

.home-quick-start-card-grid-icon {
  font-size: 60px;
  color: #1890ff
}

.home-quick-start-card-grid-h3 {
  margin-top: 34px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 22px;
  color: #262626;
  line-height: 26px;
}

p {
  margin: 15px 0 0 0;
  font-size: 18px;
  color: #697A89;
  letter-spacing: 0;
  line-height: 28px;
}

.learn-more {
  margin: 1.8rem auto;
  height: 60px;
  width: 150px;
  text-align: center;
  line-height: 60px;
  background: #0073ec;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  border-width: 2px;
  cursor:pointer
}

.learn-more a {
  font-size: 18px;
  color: #FFFFFF;
}
</style>
